import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { CSSTransition } from "react-transition-group"
import sanityClient from "../utils/client"
// import Layout from "../components/layout/layout"
import Section from "../components/section/Section"
import Seo from "../components/seo/seo"
import ButtonArrow from "../components/button/ButtonArrow"
import NavPage from "../components/nav/NavPage"

export default function SanityPage({ data: { page } }) {
  // console.log(page._id)
  const [clientProduct, setClientProduct] = useState(null)

  useEffect(() => {
    sanityClient
      .fetch(`*[_type=="page" && _id=="${page._id}" ][0]{_id,sections}`)
      .then(data => setClientProduct(data))
      .catch(console.error)
  }, [page._id])
  // if (clientProduct) {
  //   console.log(clientProduct.sections.sections)
  // }
  const [showIndex, setIndex] = useState(true)
  const sections = page?.sections?.sections
  const arrayCell = sections && sections.map(sec => sec.cells)
  const cells =
    arrayCell &&
    arrayCell.map(cell =>
      cell !== undefined ? cell.filter(s => s._type === "cell") : []
    )
  const filteredCells =
    cells && cells.map(cell => (cell.length > 0 ? cell[0].title : ""))
  const titles = filteredCells && filteredCells.filter(title => title !== "")
  const showPageIndex = page.showIndex ? page.showIndex.show : false

  const listenToScroll = () => {
    let heightToHideFrom = 500
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop
    // console.log(winScroll)
    if (winScroll > heightToHideFrom) {
      showIndex && setIndex(true)
    } else {
      setIndex(false)
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", listenToScroll)
    return () => window.removeEventListener("scroll", listenToScroll)
  })

  return (
    <div className="relative ">
      <Seo title={page?.title} />
      {titles && showPageIndex && (
        <div className="sticky top-0 z-20 hidden md:block">
          <nav className="top-0 z-20 text-white pt-45px -mt-45px md:pt-30px md:-mt-30px group">
            <div className="bg-black pointer-events-auto group md:-mt-px">
              <div className="relative top-0 z-40 flex items-center justify-between leading-none bg-black h-45px md:h-30px global-header wrap">
                <ButtonArrow
                  title={page?.showIndex?.title}
                  click="true"
                  active={showIndex}
                  onClick={() => setIndex(!showIndex)}
                ></ButtonArrow>
              </div>
            </div>
          </nav>
          <CSSTransition
            timeout={500}
            in={showIndex}
            unmountOnExit
            classNames="slideDown"
          >
            <NavPage links={titles}></NavPage>
          </CSSTransition>
        </div>
      )}
      {sections && (
        <Section
          sections={sections}
          clientSections={clientProduct?.sections?.sections}
        />
      )}
    </div>
  )
}

export const query = graphql`
  query($slug: String!) {
    page: sanityPage(slug: { current: { eq: $slug } }) {
      _id
      title
      showIndex: index {
        title
        show
      }
      sections {
        sections {
          ... on SanityCopy {
            _key
            _type
            copy {
              _key
              children {
                marks
                text
                _key
                _type
              }
              list
              style
              _type
            }
            link {
              title
              _type
              _key
              linkObject {
                ... on SanityCollection {
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityProduct {
                  id
                  _type
                  store {
                    slug {
                      current
                    }
                  }
                }
                ... on SanityPage {
                  id
                  _type
                  slug {
                    current
                  }
                }
              }
            }
          }
          ... on SanityCampaignFull {
            _key
            _type
            title
            show {
              desktop
              mobile
            }
            image {
              asset {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          ... on SanityCampaignGrid {
            _key
            _type
            image {
              _key
              asset {
                gatsbyImageData(layout: FULL_WIDTH)
                title
                url
                altText
              }
              _type
            }
            products {
              _key
              products {
                store {
                  title
                  slug {
                    current
                  }
                }
              }
            }
            video {
              asset {
                filename
                playbackId
                status
                thumbTime
                assetId
              }
            }
          }
          ... on SanityImageButtons {
            _key
            _type
            buttons {
              _key
              title
              link {
                linkObject {
                  ... on SanityCollection {
                    id
                    _type
                    slug {
                      current
                    }
                  }
                  ... on SanityProduct {
                    id
                    _type
                    store {
                      slug {
                        current
                      }
                    }
                  }
                  ... on SanityPage {
                    id
                    _type
                    slug {
                      current
                    }
                  }
                }
              }
              image {
                asset {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
          ... on SanityProductSection {
            _key
            _type
            sectionLink {
              title
              linkObject {
                ... on SanityCollection {
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityProduct {
                  id
                  _type
                  store {
                    slug {
                      current
                    }
                  }
                }
                ... on SanityPage {
                  id
                  _type
                  slug {
                    current
                  }
                }
              }
            }

            products {
              products {
                store {
                  title
                  slug {
                    current
                  }
                }
              }
            }
          }
          ... on SanityTitle {
            _key
            _type
            sanityTitle: title {
              title
              linkObject {
                ... on SanityCollection {
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityProduct {
                  id
                  _type
                  store {
                    slug {
                      current
                    }
                  }
                }
                ... on SanityPage {
                  id
                  _type
                  slug {
                    current
                  }
                }
              }
            }
          }
          ... on SanityGrid {
            _key
            _type
            columns
            cells {
              ... on SanityCell {
                _key
                _type
                content {
                  _key
                  children {
                    marks
                    text
                    _key
                    _type
                  }
                  list
                  style
                  _type
                }
                colspan
                rowspan
                subtitle
                title
              }
              ... on SanityImageCell {
                _key
                _type
                rowspan
                colspan
                image {
                  asset {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
              }
            }
            title
          }
          ... on SanityAccordion {
            _key
            _type
            title
            accordionColumns: columns {
              ... on SanityColumn {
                _key
                _type
                content {
                  children {
                    _key
                    _type
                    marks
                    text
                  }
                  list
                  style
                  _type
                  _key
                }
              }
              ... on SanityColumnImage {
                _key
                _type
                image {
                  asset {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
