import React from "react"
import "./anchor.scss"
import scrollTo from "gatsby-plugin-smoothscroll"
const Anchor = ({ title, index }) => {
  const [btnClass, setBtnClass] = React.useState("default")

  const over = () => {
    setBtnClass("over")
  }
  const out = () => {
    setBtnClass("out")
    setTimeout(function () {
      setBtnClass("default")
    }, 300)
  }
  React.useEffect(() => {
    return () => {
      setBtnClass("default")
    }
  }, [btnClass])
  function generateSlug(title) {
    return title
      .toLowerCase()
      .replace(/[^a-z0-9 -]/g, "")
      .replace(/\s+/g, "-")
      .replace(/-+/g, "-")
  }
  return (
    <button
      onClick={() => scrollTo(`#${generateSlug(title)}`)}
      className={`flex items-center transition h-45px md:h-30px px-4 md:px-2 duration-300 line-link hover:text-black group text-left`}
      onMouseOver={() => over()}
      onFocus={() => over()}
      onMouseLeave={() => out()}
    >
      <span>{index + 1}.&nbsp;
        {title}</span>
    </button>
  )
}

export default Anchor
