import React from "react"
import Anchor from "../util/Anchor"
const NavPage = ({ links }) => {
  return (
    <div className="w-full transition-transform duration-300 transform md:px-1 h-45px md:h-30px">
      <nav className="items-start -mt-px text-gray-300 columns md:px-3">
        {links.map((link, index) => {
          return (
            <span className="block w-full" key={index}>
              <Anchor title={link} index={index}>
                {link}
              </Anchor>
            </span>
          )
        })}
      </nav>
    </div>
  )
}

export default NavPage
